<!-- refer to https://www.youtube.com/watch?v=cQ1jUkRPZYw -->
<script setup lang="ts">
definePageMeta({ layout: 'auth' })

useSeoMeta({ title: 'Login' })

const appConfig = useAppConfig()
const route = useRoute()

const authFormProps = {
  title: `${appConfig.title} | Netzo`,
  description: 'Accede a tu cuenta para continuar.',
  icon: 'i-mdi-lock',
  submitButton: { trailingIcon: 'i-mdi-arrow-right' },
  ui: { base: 'text-center', footer: 'text-center' },
  providers: [
    {
      label: 'Continuar con Google',
      icon: 'i-logos-google-icon',
      color: 'white' as const,
      to: '/auth/google',
      external: true, // required for /auth (server) routes
    },
    // {
    //   label: 'Continuar con GitHub',
    //   icon: 'i-mdi-github',
    //   color: 'white' as const,
    //   to: '/auth/github',
    //   external: true, // required for /auth (server) routes
    // },
  ],
}

const error = route.query.error as string
</script>

<template>
  <UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
    <UAuthForm
      v-bind="authFormProps"
      align="top"
    >
      <template
        v-if="error"
        #description
      >
        <UAlert
          :title="error"
          variant="subtle"
          color="red"
          icon="i-mdi-alert-circle"
          class="mt-4 text-left"
        />
      </template>
      <!-- <template #footer /> -->
    </UAuthForm>
  </UCard>
</template>
